import React from "react";
import { useLocation } from "@reach/router";

import "@styles/styles.scss";
import Header from "@components/Header";
import Footer from "@components/Footer";

import {
  faPhone as faSolidPhone,
  faEnvelope as faSolidEnvelope,
  faStar as faSolidStar,
  faBars as faSolidBars,
  faMapMarkerAlt as faSolidMapMarkerAlt,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faStar as farStar,
  faChevronDown as farChevronDown,
} from "@fortawesome/pro-regular-svg-icons";
// import { faBars as faDuotoneBars } from '@fortawesome/pro-duotone-svg-icons';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";

library.add(
  // Solid icons
  faFacebookF,
  faSolidPhone,
  faSolidEnvelope,
  faSolidStar,
  farStar,
  faSolidBars,
  faSolidMapMarkerAlt,
  farChevronDown

  // Duotone icons
  // faDuotoneBars
);

// wrap your pages in a layout here...
const Layout = ({ children }) => {
  let myLocation = useLocation();
  const noLogo = myLocation?.pathname === "/";
  return (
    <div>
      <Header noLogo={noLogo} />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
