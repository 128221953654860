import React, { useState } from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./centre.module.scss";
import Container from "@components/Container";
import CustomSwiper from "@components/CustomSwiper";
import CardDropdown from "@components/CardDropdown";
import CustomBadges from "@components/CustomBadges";
import CompatibleImage from "@components/CompatibleImage";

const CentreSection = ({
  title,
  text,
  image,
  servicesHeading,
  services,
  swiperSlides,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <section className={styles.centreWrapper} id="centre">
      <CardDropdown
        title={title}
        text={text}
        image={image}
        alt={"centre section graphic"}
      />
      <Container>
        <Divider margin={4} />
        <Divider xsMargin={0} smMargin={4} />

        <CustomBadges badges={services} heading={servicesHeading} />

        <Divider margin={4} />
        <Divider margin={4} />
        <Divider xsMargin={0} smMargin={4} />
        <Divider xsMargin={0} lgMargin={4} />
      </Container>

      <div className={styles.swiperCardsWrapper}>
        <CustomSwiper
          handleActiveSlide={(e) => setSlideIndex(e)}
          spaceBetween={10}
          slidesNum={swiperSlides?.length}
          breakpointValue={768}
          breakpoints={{
            300: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 5,
            },
          }}
        >
          {swiperSlides?.map((slide, index) => (
            <SwiperSlide key={`${slide?.image?.publicURL}-${slide?.alt}`}>
              <div
                className={`${styles.cardContainer} ${
                  slideIndex === index ? styles.activeCard : ""
                } 
                `}
              >
                <CompatibleImage
                  imageInfo={{
                    image: slide?.image,
                    alt: slide?.alt,
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </CustomSwiper>
      </div>

      <Divider xsMargin={0} mdMargin={4} />
      <Divider xsMargin={0} lgMargin={4} />
      <Divider margin={4} />
    </section>
  );
};

export default CentreSection;
