import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import pricesBg from "@images/prices-bg-circles.svg";

import styles from "./hero.module.scss";
import Container from "@components/Container";
import Button from "@components/Button";
import ImageHeroText from "@components/ImageTextHero";

const Hero = ({
  heroTitle,
  heroImage,
  dayPriceBubble,
  dayPriceTitle,
  dayPriceTextTop,
  dayPriceTextBottom,
  nightPriceBubble,
  nightPriceTitle,
  nightPriceTextTop,
}) => {
  return (
    <section className={styles.heroWrapper}>
      <div className={styles.heroTopWrapper}>
        <ImageHeroText
          title={heroTitle}
          image={heroImage}
          alt="two man smiling"
        />
      </div>

      <Container>
        <div className={styles.priceWrapper}>
          <Row>
            <Col xs={12} sm={4}>
              <div
                className={styles.imageBackgroundContainer}
                style={{ backgroundImage: `url(${pricesBg})` }}
              >
                <span>{`£${dayPriceBubble}`}</span>
              </div>
            </Col>
            <Col xs={12} sm={8}>
              <div className={styles.dayTextContent}>
                <h2>{dayPriceTitle}</h2>

                <Divider margin={1} />

                <p>{dayPriceTextTop}</p>
                <div dangerouslySetInnerHTML={{ __html: dayPriceTextBottom }} />

                <Divider margin={4} />

                <Button title="Our Services" link="/services" />
              </div>
            </Col>
          </Row>
        </div>

        <div className={styles.priceWrapper}>
          <Row>
            <Col xs={12} sm={8}>
              <div className={styles.nightTextContainer}>
                <h2>{nightPriceTitle}</h2>

                <Divider margin={1} />

                <p>{nightPriceTextTop}</p>

                <Divider margin={4} />

                <Button title="Our Services" link="/services" />
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div
                className={styles.imageBackgroundContainer}
                style={{ backgroundImage: `url(${pricesBg})` }}
              >
                <span>{`£${nightPriceBubble}`}</span>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
