import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import styles from "./hero.module.scss";
import Container from "@components/Container";
import MapArea from "@components/mapArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactMap = ({ admin }) => {
  return (
    <section>
      <Container>
        <div>
          <Row>
            <Col xs={12} lg={8}>
              {admin ? (
                <div className={styles.mapWrapper}>
                  <h2 style={{ textAlign: "center" }}>
                    Map not present on preview panel
                  </h2>
                </div>
              ) : (
                <div className={styles.mapWrapper}>
                  <MapArea
                    location={{ latitude: 51.78225, longitude: 1.11136 }}
                  />
                </div>
              )}
            </Col>

            <Col xs={12} lg={4}>
              <ul className={styles.mapTextWrapper}>
                <li className={styles.mapAddress}>
                  <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />{" "}
                  <span>
                    44 Park Square West,
                    <br /> Clacton-On-Sea, Essex,
                    <br /> CO15 2NT
                  </span>
                  <Divider mdMargin={0} lgMargin={5} />
                </li>
                <li>
                  <a
                    rel="noreferrer noopener"
                    href="mailto:EducareLifeskillsCentre@hotmail.com"
                  >
                    <FontAwesomeIcon icon={["fas", "envelope"]} />{" "}
                    EducareLifeskillsCentre@hotmail.com
                  </a>
                </li>
                <li>
                  <a rel="noreferrer noopener" href="tel:07983109161">
                    <FontAwesomeIcon icon={["fas", "phone"]} /> 07983109161
                  </a>
                  <Divider margin={4} />
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default ContactMap;
