import React, { useState } from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./form.module.scss";
import Container from "@components/Container";
import Button from "@components/Button";
import CompatibleImage from "@components/CompatibleImage";

const ContactForm = ({ aboveFormText, title, text, image }) => {
  const [dob, setDob] = useState("");
  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const personalTitles = [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Dr", label: "Dr" },
    { value: "Ms", label: "Ms" },
    { value: "Prof", label: "Prof" },
    { value: "Rev", label: "Rev" },
    { value: "Lady", label: "Lady" },
    { value: "Sir", label: "Sir" },
  ];

  const careSelect = [{ value: "select", label: "Select" }];

  const careCheckboxes = [
    { name: "typeOfCareCheckboxes", label: "Need help with toileting" },
    { name: "typeOfCareCheckboxes", label: "Need help with socialising" },
    { name: "typeOfCareCheckboxes", label: "Difficulties with speech" },
    { name: "typeOfCareCheckboxes", label: "Need help with self care" },
    { name: "typeOfCareCheckboxes", label: "Blind/partially blind" },
    { name: "typeOfCareCheckboxes", label: "On medication" },
    { name: "typeOfCareCheckboxes", label: "Need help with physical tasks" },
    { name: "typeOfCareCheckboxes", label: "Deaf/partially deaf" },
    { name: "typeOfCareCheckboxes", label: "I have a type of Epilepsy" },
  ];

  const contactCheckboxes = [
    { name: "contactPreferenceCheckboxes", label: "Email" },
    { name: "contactPreferenceCheckboxes", label: "Mobile" },
    { name: "contactPreferenceCheckboxes", label: "Landline" },
  ];

  const handleSubmit = (values, actions) => {
    // alert(JSON.stringify(values, null, 2));
    // actions.setSubmitting(false);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact-demo", ...values }),
    })
      .then(() => {
        alert("Form successfully submitted");
        actions.resetForm();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => actions.setSubmitting(false));
  };

  return (
    <section className={styles.contactFormWrapper}>
      <Container>
        <Divider margin={3} />
        <div className={styles.textContainer}>
          <h4>{aboveFormText}</h4>
        </div>

        <Divider margin={5} />
        <Divider margin={5} />

        <div className={styles.formWrapper}>
          <div className={styles.heading}>
            <h2>{title}</h2>
            <Divider margin={1} />
            <div dangerouslySetInnerHTML={{ __html: text }} />
          </div>

          <Divider margin={5} />
          <Divider margin={5} />

          <div className={styles.formContainer}>
            <Formik
              initialValues={{
                title: "Mr",
                firstName: "",
                surname: "",
                dateOfBirth: "",
                email: "",
                mobileNumber: "",
                landlineNumber: "",
                careType: "select",
                typeOfCareCheckboxes: [],
                contactPreferenceCheckboxes: [],
              }}
              validationSchema={yupObjectSchema}
              onSubmit={handleSubmit}
            >
              {({
                onBlur,
                onChange,
                touched,
                handleChange,
                errors,
                values,
                setFieldValue,
              }) => (
                <Form name="contact-demo" data-netlify={true}>
                  <Row>
                    <Col xs={12} sm={4}>
                      <div className={styles.formField}>
                        <label htmlFor="title">Title: </label>
                        <FontAwesomeIcon
                          className={styles.chevronDown}
                          icon={["far", "chevron-down"]}
                        />
                        <select name="title" onChange={handleChange}>
                          {personalTitles?.map((title, index) => (
                            <option
                              key={`${title?.value}-${index}`}
                              value={title?.value}
                              label={title?.label}
                            />
                          ))}
                        </select>
                        {touched.title && errors.title && (
                          <span>{errors.title}</span>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} sm={8} md={6}>
                      <div className={styles.formField}>
                        <label htmlFor="firstName">First name</label>
                        <input
                          name="firstName"
                          onChange={handleChange}
                          placeholder="Your first name..."
                        />
                        {touched.firstName && errors.firstName && (
                          <span>{errors.firstName}</span>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} sm={8}>
                      <div className={styles.formField}>
                        <label htmlFor="surname">Surname</label>
                        <input
                          name="surname"
                          onChange={handleChange}
                          placeholder="Your surname..."
                        />
                        {touched.surname && errors.surname && (
                          <span>{errors.surname}</span>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} sm={7}>
                      <div className={styles.formField}>
                        <label htmlFor="dateOfBirth">Date of birth</label>
                        <DatePicker
                          name="dateOfBirth"
                          selected={dob}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            setDob(date);
                            setFieldValue(
                              "dateOfBirth",
                              date instanceof Date
                                ? format(date, "dd-MM-yyyy")
                                : date
                            );
                          }}
                          showYearDropdown
                          showMonthDropdown
                          maxDate={new Date()}
                          placeholderText="Your date of birth..."
                          dropdownMode="select"
                        />
                        {touched.dateOfBirth && errors.dateOfBirth && (
                          <span>{errors.dateOfBirth}</span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={12}>
                      <div className={styles.formField}>
                        <label htmlFor="email">Email</label>
                        <input
                          name="email"
                          onChange={handleChange}
                          placeholder="Your email..."
                        />
                        {touched.email && errors.email && (
                          <span>{errors.email}</span>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} sm={6}>
                      <div className={styles.formField}>
                        <label htmlFor="mobileNumber">Mobile Number</label>
                        <input
                          name="mobileNumber"
                          onChange={handleChange}
                          placeholder="Your mobile number..."
                        />
                        {touched.mobileNumber && errors.mobileNumber && (
                          <span>{errors.mobileNumber}</span>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} sm={6}>
                      <div className={styles.formField}>
                        <label htmlFor="landlineNumber">Landline Number</label>
                        <input
                          name="landlineNumber"
                          onChange={handleChange}
                          placeholder="Your landline number..."
                        />
                        {touched.landlineNumber && errors.landlineNumber && (
                          <span>{errors.landlineNumber}</span>
                        )}
                      </div>
                    </Col>

                    <Col xs={12} sm={7}>
                      <div className={styles.formField}>
                        <label htmlFor="careType">Type of care required</label>
                        <FontAwesomeIcon
                          className={styles.chevronDown}
                          icon={["far", "chevron-down"]}
                        />
                        <select name="careType" onChange={handleChange}>
                          {careSelect?.map((title, index) => (
                            <option
                              key={`${title?.value}-${index}`}
                              value={title?.value}
                              label={title?.label}
                            />
                          ))}
                        </select>
                        {touched.careType && errors.careType && (
                          <span>{errors.careType}</span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <div className={styles.textInformation}>
                        <span>
                          More information about the care you’ll require:
                        </span>
                        <span className={styles.tip}>
                          (Please select all that apply)
                        </span>
                        <Divider margin={4} />
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className={styles.checkboxesContainer}>
                        {careCheckboxes?.map((care) => (
                          <label key={care?.name}>
                            <Field
                              type="checkbox"
                              name={care?.name}
                              value={care?.label}
                            />
                            <span>{care?.label}</span>
                          </label>
                        ))}
                      </div>
                      <Divider margin={4} />
                      <Divider margin={4} />
                    </Col>

                    <Col xs={12}>
                      <div className={styles.textInformation}>
                        <span>How would you like us to contact you?</span>
                        <span className={styles.tip}>
                          (Please select all that apply)
                        </span>
                        <Divider margin={4} />
                      </div>
                    </Col>

                    <Col xs={12}>
                      <div className={styles.checkboxesContainer}>
                        {contactCheckboxes?.map((care) => (
                          <label key={care?.name}>
                            <Field
                              type="checkbox"
                              name={care?.name}
                              value={care?.label}
                            />
                            <span>{care?.label}</span>
                          </label>
                        ))}
                      </div>
                    </Col>
                  </Row>

                  <Divider margin={4} />
                  <Divider margin={4} />
                  <Divider margin={4} />

                  <Row>
                    <Col xs={12}>
                      <div className={styles.submitContainer}>
                        <span>
                          Once you press send, will be in contact with you as
                          soon as we possibly can!
                        </span>
                        {/*<button type="submit">Send</button>*/}
                        <Divider margin={3} />
                        <Button title="Send" type="submit" />

                        <Divider margin={4} />
                        <Divider margin={4} />

                        <p>
                          Any Information disclosed will remain private in
                          accordance with our Privacy Policies
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>

        <Divider margin={4} />
        <Divider margin={4} />

        <CompatibleImage
          imageInfo={{
            image: image,
            alt: "contact form graphic",
          }}
        />
      </Container>
    </section>
  );
};

export default ContactForm;

const yupObjectSchema = Yup.object().shape({
  title: Yup.string().lowercase().required("Title is required"),
  firstName: Yup.string()
    .required("First Name is required")
    .min(2, "First Name must be at least 2 characters")
    .max(50, "First Name cannot be more than 50 characters"),
  surname: Yup.string()
    .required("Surname is required")
    .min(2, "Surname must be at least 2 characters")
    .max(50, "Surname cannot be more than 50 characters"),
  dateOfBirth: Yup.string().lowercase().required("Date of Birth is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Must be an email address")
    .min(2, "Email must be at least 2 characters")
    .max(70, "Email cannot be more than 70 characters"),
  mobileNumber: Yup.number(),
  landlineNumber: Yup.number(),
  careType: Yup.string(),
});
