import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import styles from "./styles.module.scss";

const CustomBadges = ({ badges, heading }) => {
  return (
    <div className={styles.badgesWrapper}>
      <h3>{heading}</h3>
      <Row>
        {badges?.map((badge) => (
          <Col xs={12} sm={6} md={4}>
            <div className={styles.customBadge}>
              <span>{badge?.name}</span>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CustomBadges;
