import React, { useState } from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";
import { Swiper, SwiperSlide } from "swiper/react";
import { format } from "date-fns";

import styles from "./reosourceCenter.module.scss";
import Container from "@components/Container";
import Button from "../../components/Button";
import CustomSwiper from "@components/CustomSwiper";
import CompatibleImage from "@components/CompatibleImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ResourceCenter = ({
  heading,
  cards,
  donationTitle,
  donationText,
  donationBtnText,
  reviews,
}) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const getStars = (stars) => {
    let result = [];
    for (let i = 0; i < 5; i++) {
      if (i < stars) result.push("fas");
      else result.push("far");
    }
    return result;
  };

  return (
    <div className={styles.resourceCenterWrapper}>
      <section className={styles.resourceCardsWrapper}>
        <Container small>
          <h2>{heading}</h2>

          <Divider margin={5} />
          <Divider xsMargin={0} smMargin={5} />

          <div className={styles.cardsContainer}>
            <Row between>
              {cards?.map((card, index) => (
                <Col xs={12} md={6} key={`${card?.title}-${index}`}>
                  <div className={styles.imageCard}>
                    <CompatibleImage
                      imageInfo={{
                        image: card?.image,
                        alt: `${card?.title} graphic`,
                      }}
                    />
                    <h2>{card?.title}</h2>
                    <Button
                      title="More Information"
                      gradient
                      link={card?.link}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section>

      <Divider smMargin={0} mdMargin={5} />

      <section className={styles.ctaWrapper} id="charities">
        <Container small>
          <div className={styles.ctaContainer}>
            <h2>{donationTitle}</h2>
            <p>{donationText}</p>
            <Button
              title={donationBtnText}
              link={"https://www.paypal.com/paypalme/BluebirdRC"}
            />
          </div>
        </Container>
      </section>

      <section className={styles.reviewsWrapper}>
        <CustomSwiper
          handleActiveSlide={(e) => setSlideIndex(e)}
          spaceBetween={20}
          slidesNum={reviews?.length}
          breakpointValue={1400}
          breakpoints={{
            300: {
              slidesPerView: 1,
            },
            1400: {
              slidesPerView: 3,
            },
          }}
        >
          {reviews?.map(({ image, name, date, stars, title, text }, index) => (
            <SwiperSlide key={`${name}_${index}`}>
              <div
                className={`${styles.reviewsCardContainer} ${
                  slideIndex === index ? styles.activeCard : ""
                } 
                `}
              >
                <div className={styles.cardTop}>
                  <div className={styles.imageContainer}>
                    <CompatibleImage
                      imageInfo={{
                        image: image,
                        alt: `photo box`,
                      }}
                    />
                  </div>
                  <div className={styles.textContainer}>
                    <h3>{name}</h3>
                    <span>{date}</span>
                    <Divider margin={2} />

                    <div className={styles.starsContainer}>
                      {getStars(stars)?.map((starType) => (
                        <span>
                          <FontAwesomeIcon icon={[starType, "star"]} />
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.cardBottom}>
                  <h4>{title}</h4>
                  <p>{text}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </CustomSwiper>
      </section>
    </div>
  );
};

export default ResourceCenter;
