import React from "react";
import { Link } from "gatsby";

import styles from "./styles.module.scss";

const Button = ({ title, secondary, gradient, link, ...otherProps }) => {
  if (!!link)
    if (link.includes("http"))
      return (
        <a
          className={`${
            gradient
              ? styles.buttonGradient
              : secondary
              ? styles.buttonSecondary
              : styles.buttonPrimary
          }`}
          href={`${link}`}
          rel="noreferrer noopener"
          target="_blank"
          {...otherProps}
        >
          {title}
        </a>
      );
    else
      return (
        <Link
          className={`${
            gradient
              ? styles.buttonGradient
              : secondary
              ? styles.buttonSecondary
              : styles.buttonPrimary
          }`}
          to={`${link}`}
          {...otherProps}
        >
          {title}
        </Link>
      );

  return (
    <button
      className={`${
        gradient
          ? styles.buttonGradient
          : secondary
          ? styles.buttonSecondary
          : styles.buttonPrimary
      }`}
      {...otherProps}
    >
      {title}
    </button>
  );
};

export default Button;
