import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Hero from "@views/services/Hero";
import Layout from "@components/Layout";
import CentreSection from "@views/services/CentreSection";
import Enterprise from "@views/services/Enterprise";
import Community from "@views/services/Community";
import SEO from "@components/SEO";

export const ServicesPageTemplate = ({
  hero,
  centre,
  enterprise,
  community,
  admin,
}) => (
  <div>
    {!admin && <SEO title={`Services`} />}
    <Hero
      title={hero?.hero_title}
      text={hero?.hero_text}
      heroImage={hero?.hero_image}
    />
    <CentreSection
      title={centre?.centre_title}
      image={centre?.centre_image}
      text={centre?.centre_text}
      servicesHeading={centre?.centre_services_heading}
      services={centre?.centre_services}
      swiperSlides={centre?.centre_swiper}
    />
    <Enterprise
      background={enterprise?.enterprise_heading_background}
      title={enterprise?.enterprise_heading_title}
      headingText={enterprise?.enterprise_heading_text}
      cards={enterprise?.enterprise_cards}
      ctaText={enterprise?.enterprise_cta_text}
      ctaButtonText={enterprise?.enterprise_cta_button_text}
      ctaButtonLink={enterprise?.enterprise_cta_button_link}
    />
    <Community
      rewardsTitle={community?.community_rewards_title}
      rewardsImage={community?.community_rewards_image}
      rewardsText={community?.community_rewards_text}
      outingsText={community?.community_outings_text}
      outingsImage={community?.community_outings_image}
      communityCardTitle={community?.community_card_title}
      communityCardImage={community?.community_card_image}
      activityHeading={community?.community_activity_heading}
      activityCards={community?.community_activity_cards}
    />
  </div>
);

ServicesPageTemplate.propTypes = {
  hero: PropTypes.object,
  centre: PropTypes.object,
  enterprise: PropTypes.object,
  community: PropTypes.object,
};

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ServicesPageTemplate
        hero={frontmatter?.hero}
        centre={frontmatter?.centre}
        enterprise={frontmatter?.enterprise}
        community={frontmatter?.community}
      />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ServicesPage;

export const pageQuery = graphql`
  query ServicesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
      frontmatter {
        hero {
          hero_title
          hero_text
          hero_image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        centre {
          centre_title
          centre_image {
            publicURL
          }
          centre_text
          centre_services_heading
          centre_services {
            name
          }
          centre_swiper {
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
        }
        enterprise {
          enterprise_heading_background {
            publicURL
          }
          enterprise_heading_title
          enterprise_heading_text
          enterprise_cards {
            title: title
            text: text
          }
          enterprise_cta_text
          enterprise_cta_button_text
          enterprise_cta_button_link
        }
        community {
          community_rewards_title
          community_rewards_image {
            publicURL
          }
          community_rewards_text
          community_outings_text
          community_outings_image {
            publicURL
          }
          community_card_title
          community_card_image {
            publicURL
          }
          community_activity_heading
          community_activity_cards {
            name
          }
        }
      }
    }
  }
`;
