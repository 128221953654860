import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "@components/SEO";

import Layout from "@components/Layout";
import TermsAndPrivacy from "@components/TermsAndPrivacy";

export const PrivacyPageTemplate = ({ content, admin }) => (
  <div>
    {!admin && <SEO title={`Privacy Policy`} />}
    <TermsAndPrivacy
      title={content?.content_title}
      text={content?.content_text}
    />
  </div>
);

PrivacyPageTemplate.propTypes = {
  content: PropTypes.object,
};

const PrivacyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PrivacyPageTemplate content={frontmatter?.content} />
    </Layout>
  );
};

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PrivacyPage;

export const pageQuery = graphql`
  query PrivacyPolicyPageTemplate {
    markdownRemark(
      frontmatter: { templateKey: { eq: "privacy-policy-page" } }
    ) {
      frontmatter {
        content {
          content_title
          content_text
        }
      }
    }
  }
`;
