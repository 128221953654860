import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination, Navigation } from "swiper";

import styles from "./styles.module.scss";

SwiperCore.use([Pagination, Navigation]);

const CustomSwiper = ({
  children,
  handleActiveSlide,
  spaceBetween,
  slidesNum,
  breakpointValue,
  ...otherProps
}) => {
  const [swiper, setSwiper] = useState(null);
  const [slideIndex, setSlideIndex] = useState(0);
  // const activePartner = partners?.[slideIndex];

  const handleSlideIndex = (swiper) => {
    const nextIndex = swiper.realIndex + 1;
    handleActiveSlide(
      window?.innerWidth <= breakpointValue
        ? swiper.realIndex
        : nextIndex === slidesNum
        ? 0
        : nextIndex
    );
    setSlideIndex(
      window?.innerWidth <= breakpointValue
        ? swiper.realIndex
        : nextIndex === slidesNum
        ? 0
        : nextIndex
    );
  };
  return (
    <Swiper
      className={styles.customSwiperWrapper}
      slidesPerView={3}
      spaceBetween={spaceBetween}
      onInit={(swiper) => {
        setSwiper(swiper);
      }}
      loop
      pagination={{ clickable: true }}
      onSlideChange={handleSlideIndex}
      {...otherProps}
    >
      {children}
    </Swiper>
  );
};

export default CustomSwiper;
