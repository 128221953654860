import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import logo from "@images/bb-logo-1c-white.svg";

import styles from "./hero.module.scss";
import Container from "@components/Container";
import Button from "@components/Button";
import SocialContactIcons from "@components/SocialContactIcons";
import CompatibleImage from "@components/CompatibleImage";

const Hero = ({ title, text, heroImage }) => {
  return (
    <section className={styles.heroWrapper}>
      <div className={styles.logoDesktop}>
        <img src={logo} alt="Educare logo" />
      </div>
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={12} lg={3}>
                <div className={styles.logoWrapper}>
                  <img src={logo} alt="Educare logo" />
                </div>
              </Col>
              <Col xs={12} lg={9}>
                <div className={styles.textWrapper}>
                  <h1>{title}</h1>
                  <p>{text}</p>
                  <Button title="Our Services" link="/services" />
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs={12} lg={6}>
            <div className={styles.imageWrapper}>
              <CompatibleImage
                imageInfo={{
                  image: heroImage,
                  alt: `Educare logo`,
                }}
              />
            </div>

            <Divider />

            <div className={styles.mobileButtonWrapper}>
              <Button title="Our Services" link="/services" />
            </div>

            <div className={styles.socialIconsWrapper}>
              <SocialContactIcons />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
