import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Hero from "@views/contact/Hero";
import Layout from "@components/Layout";
import ContactForm from "@views/contact/ContactForm";
import SEO from "@components/SEO";
import ContactMap from "@views/contact/ContactMap";

export const ContactPageTemplate = ({ hero, form, admin }) => (
  <div>
    {!admin && <SEO title={`Contact Us`} />}
    <Hero heroTitle={hero?.hero_title} heroImage={hero?.hero_image} />
    <ContactMap admin={admin} />
    <ContactForm
      aboveFormText={form?.above_form_text}
      title={form?.form_title}
      text={form?.form_text}
      image={form?.form_image}
    />
  </div>
);

ContactPageTemplate.propTypes = {
  hero: PropTypes.object,
  form: PropTypes.object,
};

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ContactPageTemplate hero={frontmatter?.hero} form={frontmatter?.form} />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        hero {
          hero_title
          hero_image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        form {
          above_form_text
          form_title
          form_text
          form_image {
            publicURL
          }
        }
      }
    }
  }
`;
