import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import styles from "./hero.module.scss";
import Container from "@components/Container";
import CompatibleImage from "@components/CompatibleImage";
import ImageHeroText from "@components/ImageTextHero";

const Hero = ({ title, text, heroImage }) => {
  return (
    <section className={styles.heroWrapper}>
      <ImageHeroText title={title} image={heroImage} alt="two man smiling" />
      <Container small>
        <div className={styles.heroTextWrapper}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </Container>

      <Divider margin={3} />
      <Divider margin={3} />
    </section>
  );
};

export default Hero;
