import React from "react";

import styles from "./styles.module.scss";

const Container = ({ small, children }) => {
  return (
    <div
      className={`${
        small ? styles.smallContainerWrapper : styles.containerWrapper
      }`}
    >
      {children}
    </div>
  );
};

export default Container;
