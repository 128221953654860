import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import yellowBg from "@images/home-about-yellow-bg.svg";
import blueDots from "@images/home-about-blue-dots-shape.svg";
import blueBg from "@images/home-about-dark-blue-bg.svg";
import pinkBg from "@images/home-about-pink-bg.svg";
import greenBg from "@images/home-about-green-bg.svg";

import styles from "./aboutSection.module.scss";
import Container from "@components/Container";
import CompatibleImage from "@components/CompatibleImage";

const AboutSection = ({
  headingImage,
  headingTitle,
  headingText,
  shapeTitle1,
  shapeText1,
  shapeTitle2,
  shapeText2,
  shapeTitle3,
  shapeText3,
  shapeTitle4,
  shapeText4,
}) => {
  return (
    <div className={styles.aboutWrapper} id="about-us">
      <section>
        <Container small>
          <div className={styles.aboutTopWrapper}>
            <div className={styles.imageContainer}>
              <CompatibleImage
                imageInfo={{
                  image: headingImage,
                  alt: `photo box`,
                }}
              />
            </div>

            <Divider margin={4} />
            <Divider xsMargin={0} smMargin={4} />

            <div className={styles.textContainer}>
              <h2>{headingTitle}</h2>
              <div dangerouslySetInnerHTML={{ __html: headingText }} />
              {/*<Content content={headingText} />*/}
            </div>
          </div>
        </Container>
      </section>

      <section className={styles.aboutBottomWrapper} id="testimonials">
        <div
          className={styles.yellowShapeContainer}
          style={{ backgroundImage: `url(${yellowBg})` }}
        >
          {/*<img src={blueDots} alt={"blue dots"} />*/}
          <div>
            <h3>{shapeTitle1}</h3>
            <p>{shapeText1}</p>
          </div>
        </div>

        <div
          className={styles.blueShapeContainer}
          style={{ backgroundImage: `url(${blueBg})` }}
        >
          <div>
            <h3>{shapeTitle2}</h3>
            <p>{shapeText2}</p>
          </div>
        </div>

        <div
          className={styles.pinkShapeContainer}
          style={{ backgroundImage: `url(${pinkBg})` }}
        >
          <div>
            <h3>{shapeTitle3}</h3>
            <p>{shapeText3}</p>
          </div>
        </div>

        <div
          className={styles.greenShapeContainer}
          style={{ backgroundImage: `url(${greenBg})` }}
        >
          <div>
            <h3>{shapeTitle4}</h3>
            <p>{shapeText4}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutSection;
