import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import styles from "./enterprise.module.scss";
import Container from "@components/Container";
import Button from "@components/Button";

const Enterprise = ({
  background,
  title,
  headingText,
  cards,
  ctaText,
  ctaButtonText,
  ctaButtonLink,
}) => {
  return (
    <section className={styles.enterpriseWrapper} id="enterprise">
      <Container small>
        <div
          className={styles.headingTitleContainer}
          style={{
            backgroundImage: `url(${
              !!background?.publicURL ? background?.publicURL : background
            })`,
          }}
        >
          <h2>{title}</h2>
        </div>

        <Divider xsMargin={0} smMargin={5} />

        <div className={styles.headingTextContainer}>
          <div dangerouslySetInnerHTML={{ __html: headingText }} />
        </div>

        <Divider margin={5} />
        <Divider xsMargin={0} smMargin={5} />

        <div className={styles.enterpriseCardsContainer}>
          <Row>
            {cards?.map((card) => (
              <Col xs={12} md={6}>
                <div className={styles.enterpriseCard}>
                  <h3>{card?.title}</h3>
                  <p>{card?.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <Divider margin={5} />
        <Divider xsMargin={0} smMargin={3} />

        <div className={styles.ctaContainer}>
          <div dangerouslySetInnerHTML={{ __html: ctaText }} />
          <Button title={ctaButtonText} link={ctaButtonLink} gradient />
        </div>
      </Container>

      <Divider margin={5} />
      <Divider xsMargin={0} smMargin={5} />
    </section>
  );
};

export default Enterprise;
