import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import topSquiggle from "@images/prices-top-card-squiggle.svg";
import bottomSquiggle from "@images/prices-bottom-card-squiggle.svg";

import styles from "./cardsPrices.module.scss";
import Container from "@components/Container";
import ContactInfo from "@components/ContactsInfo";

const CardsPrices = ({
  dayTitle,
  dayQuestion,
  dayText,
  nightTitle,
  nightQuestion,
  nightText,
}) => {
  return (
    <section>
      <div className={styles.pricesCardsWrapper}>
        <Divider margin={5} />
        <Divider margin={5} />
        <Row>
          <Col xs={12} md={6}>
            <div className={styles.topSquiggle}>
              <img src={topSquiggle} alt={"prices card top squiggle"} />
            </div>
            <div className={styles.cardContainer}>
              <h2>{dayTitle}</h2>

              <Divider margin={3} />
              <span>{dayQuestion}</span>
              <Divider margin={3} />

              <div dangerouslySetInnerHTML={{ __html: dayText }} />
            </div>
          </Col>

          <Col xs={12} md={6}>
            <div className={styles.cardContainer}>
              <h2>{nightTitle}</h2>

              <Divider margin={3} />
              <span>{nightQuestion}</span>
              <Divider margin={3} />

              <div dangerouslySetInnerHTML={{ __html: nightText }} />
            </div>

            <div className={styles.bottomSquiggle}>
              <img src={bottomSquiggle} alt={"prices card top squiggle"} />
            </div>
          </Col>
        </Row>
      </div>

      <Divider margin={5} />
      <Divider margin={5} />
      <Divider margin={5} />
      <Divider xsMargin={0} smMargin={5} />
      <Divider xsMargin={0} lgMargin={5} />

      <Container>
        <ContactInfo title="To enquire about availability, please contact us!" />
      </Container>
    </section>
  );
};

export default CardsPrices;
