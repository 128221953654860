import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import styles from "./styles.module.scss";
import Container from "@components/Container";

const TermsAndPrivacy = ({ title, text }) => {
  return (
    <div className={styles.contentWrapper}>
      <Divider margin={5} />
      <Divider margin={5} />
      <Divider xsMargin={0} smMargin={0} mdMargin={5} />
      <Divider xsMargin={0} smMargin={0} mdMargin={5} />
      <Container small>
        <h1>{title}</h1>
        <div
          className={styles.textContainer}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Container>
    </div>
  );
};

export default TermsAndPrivacy;
