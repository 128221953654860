import React from "react";

import styles from "./styles.module.scss";
import Button from "@components/Button";

const ContactInfo = ({ title, prices }) => {
  return (
    <div className={styles.contactInfoWrapper}>
      <h3>{title}</h3>
      <div>
        {prices ? (
          <Button title="Prices" link="/prices" secondary />
        ) : (
          <Button title="Services" link="/services" secondary />
        )}

        <Button title="Contact Us" link="/contact" />
      </div>
    </div>
  );
};

export default ContactInfo;
