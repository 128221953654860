import React from "react";

import Layout from "../components/Layout";
import SEO from "@components/SEO";
import Container from "@components/Container";
import styles from "./404.styles.module.scss";
const NotFoundPage = () => (
  <Layout>
    <div className={styles.notFoundWrapper}>
      <div className={styles.notFound}>
        <Container>
          <SEO title={`Page Not Found`} />
          <h1>404 NOT FOUND</h1>
          <p>You just hit a route that does not exit</p>
        </Container>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
