import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import patternBar from "@images/footer-pattern-bar.svg";
import logoBlue from "@images/bb-logo-blue.svg";

import styles from "./styles.module.scss";
import Container from "@components/Container";
import { Link } from "gatsby";
import SocialContactIcons from "@components/SocialContactIcons";

const Footer = () => {
  return (
    <footer className={styles.footerWrapper}>
      <div
        className={styles.patternBar}
        style={{ backgroundImage: `url(${patternBar})` }}
      />
      <Divider margin={3} />
      <Container>
        <div className={styles.footerTopWrapper}>
          <Row>
            <Col xs={12} md={3}>
              <div className={styles.imageCopyrightWrapper}>
                <div className={styles.imageContainer}>
                  <img src={logoBlue} alt="Educare logo" />
                </div>
              </div>
            </Col>

            <Col xs={12} md={9}>
              <div className={styles.linksInfoWrapper}>
                <Row>
                  <Col xs={12} sm={6} md={4}>
                    <div className={styles.infoContainer}>
                      <h4>About Us</h4>
                      <ul>
                        <li>
                          <Link to="/#about-us">About Us</Link>
                        </li>
                        <li>
                          <Link to="/#testimonials">Testimonials</Link>
                        </li>
                        <li>
                          <Link to="/contact">Make an Enquiry</Link>
                        </li>
                        <li>
                          <Link to="/#charities">Our Charities</Link>
                        </li>
                        <li>
                          <Link to="/contact">Submit an issue</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} md={4}>
                    <div className={styles.infoContainer}>
                      <h4>Our Services</h4>
                      <ul>
                        <li>
                          <Link to="/services">Our Services</Link>
                        </li>
                        <li>
                          <Link to="/services/#enterprise">Enterprise</Link>
                        </li>
                        <li>
                          <Link to="/services/#centre">Our Centre</Link>
                        </li>
                        <li>
                          <Link to="/services/#community">
                            In the Community
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>

                  <Col xs={12} sm={6} md={4}>
                    <div className={styles.infoContainer}>
                      <h4>Contact us</h4>
                      <ul>
                        <li>
                          <a rel="noreferrer noopener" href="tel:07983109161">
                            07983109161
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noreferrer noopener"
                            href="mailto:EducareLifeskillsCentre@hotmail.com"
                          >
                            EducareLifeskillsCentre@hotmail.com
                          </a>
                        </li>
                        <li>
                          <span>
                            44 Park Square West,
                            <br /> Clacton-On-Sea, <br />
                            Essex, <br />
                            CO15 2NT
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <div className={styles.footerMobileTop}>
        <Container>
          <ul>
            <li>
              <img src={logoBlue} alt="Bluebird logo" />
            </li>
            <li>
              <Link to="/#about-us">About Us</Link>
            </li>
            <li>
              <Link to="/services">Our Services</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
          </ul>
          <div className={styles.socialIcons}>
            <SocialContactIcons />
          </div>
        </Container>
      </div>

      <div className={styles.footerBottomWrapper}>
        <Container>
          <Row>
            <Col xs={12} md={3}>
              <div className={styles.copyrightContainer}>
                <p>© Educare Lifeskills Centre 2024</p>
              </div>
            </Col>
            <Col xs={12} md={9}>
              <Row end={"md"}>
                <Col xs={12} sm={12} md={4}>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </Col>
                <Col xs={12} sm={12} md={4}>
                  <div className={styles.socialIcons}>
                    <SocialContactIcons />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
