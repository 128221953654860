import React from "react";

import styles from "./styles.module.scss";
import CompatibleImage from "@components/CompatibleImage";
import Container from "@components/Container";

const ImageHeroText = ({ image, alt, title, contactPage }) => {
  return (
    <div
      className={`${styles.imageTextHeroWrapper} ${
        contactPage ? styles.contactPageHeroWrapper : ""
      }`}
    >
      <CompatibleImage
        imageInfo={{
          image: image,
          alt: alt,
        }}
      />
      <Container small>
        <h1>{title}</h1>
      </Container>
    </div>
  );
};

export default ImageHeroText;
