import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import styles from "./community.module.scss";
import CardDropdown from "@components/CardDropdown";
import Container from "@components/Container";
import CustomBadges from "@components/CustomBadges";
import Button from "@components/Button";
import ContactInfo from "@components/ContactsInfo";
import CompatibleImage from "@components/CompatibleImage";

const Community = ({
  rewardsTitle,
  rewardsImage,
  rewardsText,
  outingsText,
  outingsImage,
  communityCardTitle,
  communityCardImage,
  activityHeading,
  activityCards,
}) => {
  return (
    <section id="community">
      <CardDropdown
        title={rewardsTitle}
        text={rewardsText}
        image={rewardsImage}
        alt={"rewards section graphic"}
      />
      <Container small>
        <Divider margin={5} />
        <Divider xsMargin={0} smMargin={5} />

        <div className={styles.outingsCardWrapper}>
          <Row>
            <Col xs={12} md={3}>
              <div className={styles.outingsCardImage}>
                {/*<img src={outingsImage?.publicURL} alt="outings graphic" />*/}
                <CompatibleImage
                  imageInfo={{
                    image: outingsImage,
                    alt: "outings graphic",
                  }}
                />
              </div>
            </Col>
            <Col xs={12} md={9}>
              <div className={styles.outingsCardText}>
                <p>{outingsText}</p>
              </div>
            </Col>
          </Row>
        </div>

        <Divider margin={5} />
        <Divider margin={5} />
        <Divider xsMargin={0} smMargin={5} />
      </Container>

      <CardDropdown
        title={communityCardTitle}
        image={communityCardImage}
        alt={"community card graphic"}
      />

      <Container>
        <Divider margin={5} />
        <Divider margin={3} />
        <Divider xsMargin={0} smMargin={5} />

        <CustomBadges heading={activityHeading} badges={activityCards} />

        <Divider margin={5} />
        <Divider margin={5} />
        <Divider xsMargin={0} smMargin={5} />

        <ContactInfo
          title="Let’s create your tailor-made service plan"
          prices
        />
      </Container>
    </section>
  );
};

export default Community;
