import React, { useState } from "react";

import logo from "@images/bb-logo-1c-white.svg";

import styles from "./styles.module.scss";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({ noLogo }) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const linksList = [
    { title: "Home", link: "/" },
    { title: "Services", link: "/services" },
    { title: "Prices", link: "/prices" },
    { title: "Contact", link: "/contact" },
  ];
  return (
    <header className={styles.headerWrapper}>
      <div
        className={`${styles.desktopHeaderWrapper} ${
          noLogo ? styles.noLogoDesktop : ""
        }`}
      >
        {!noLogo && (
          <div className={styles.logoContainer}>
            <img src={logo} alt="Educare logo" />
          </div>
        )}

        <div className={styles.linksContainer}>
          <ul>
            {linksList?.map((item) => (
              <li>
                <Link to={item?.link}>{item?.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={styles.mobileHeaderWrapper}
        onClick={() => setOpenMobileMenu(true)}
      >
        <FontAwesomeIcon
          className={`${noLogo ? styles.whiteBars : ""}`}
          icon={["fas", "bars"]}
        />
      </div>
      <div
        className={`${
          openMobileMenu ? styles.mobileMenuContainer : styles.closedMenu
        }`}
      >
        <div
          className={`${styles.mobileCloseMenu}`}
          onClick={() => setOpenMobileMenu(false)}
        >
          <FontAwesomeIcon icon={["fas", "bars"]} />
        </div>
        <ul>
          {linksList?.map((item) => (
            <li>
              <Link to={item?.link}>{item?.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  );
};

export default Header;
