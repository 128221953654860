import React from "react";
import styles from "./styles.module.scss";
import CompatibleImage from "@components/CompatibleImage";

const CardDropdown = ({ title, image, text, alt }) => {
  return (
    <div className={styles.cardDropdownWrapper}>
      <div className={styles.topContainer}>
        <h2>{title}</h2>
        {!!image && !!alt && (
          <CompatibleImage
            imageInfo={{
              image: image,
              alt: alt,
            }}
          />
        )}
      </div>

      {!!text && (
        <div
          className={styles.bottomContainer}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
    </div>
  );
};

export default CardDropdown;
