import React from "react";
import { Grid, Row, Divider, Col } from "@element-softworks/daintree";

import styles from "./hero.module.scss";
import ImageHeroText from "@components/ImageTextHero";
import Container from "@components/Container";
import MapArea from "@components/mapArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Hero = ({ heroTitle, heroImage }) => {
  return (
    <section className={styles.heroWrapper}>
      <ImageHeroText
        title={heroTitle}
        image={heroImage}
        alt="two man smiling"
      />
      <Divider margin={5} />
    </section>
  );
};

export default Hero;
