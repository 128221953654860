import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Hero from "@views/prices/Hero";
import Layout from "@components/Layout";
import CardsPrices from "@views/prices/CardsPrices";
import SEO from "@components/SEO";

export const PricesPageTemplate = ({ hero, cards_prices, admin }) => (
  <div>
    {!admin && <SEO title={`Prices`} />}
    <Hero
      heroTitle={hero?.hero_title}
      heroImage={hero?.hero_image}
      dayPriceBubble={hero?.hero_day_price_bubble}
      dayPriceTitle={hero?.hero_day_price_title}
      dayPriceTextTop={hero?.hero_day_price_text_top}
      dayPriceTextBottom={hero?.hero_day_price_text_bottom}
      nightPriceBubble={hero?.hero_night_price_bubble}
      nightPriceTitle={hero?.hero_night_price_title}
      nightPriceTextTop={hero?.hero_night_price_text_top}
    />
    <CardsPrices
      dayTitle={cards_prices?.cards_prices_day_title}
      dayQuestion={cards_prices?.cards_prices_day_question}
      dayText={cards_prices?.cards_prices_day_text}
      nightTitle={cards_prices?.cards_prices_night_title}
      nightQuestion={cards_prices?.cards_prices_night_question}
      nightText={cards_prices?.cards_prices_night_text}
    />
  </div>
);

PricesPageTemplate.propTypes = {
  hero: PropTypes.object,
  cards_prices: PropTypes.object,
};

const PricesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PricesPageTemplate
        hero={frontmatter?.hero}
        cards_prices={frontmatter?.cards_prices}
      />
    </Layout>
  );
};

PricesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PricesPage;

export const pageQuery = graphql`
  query PricesPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "prices-page" } }) {
      frontmatter {
        hero {
          hero_title
          hero_image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          hero_day_price_bubble
          hero_day_price_title
          hero_day_price_text_top
          hero_day_price_text_bottom
          hero_night_price_bubble
          hero_night_price_title
          hero_night_price_text_top
        }
        cards_prices {
          cards_prices_day_title
          cards_prices_day_question
          cards_prices_day_text
          cards_prices_night_title
          cards_prices_night_question
          cards_prices_night_text
        }
      }
    }
  }
`;
