import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles.module.scss";

const SocialContactIcons = ({ inverted, flexEnd }) => {
  return (
    <div
      className={`${
        inverted ? styles.socialIconsInvertedWrapper : styles.socialIconsWrapper
      }`}
      style={{ justifyContent: flexEnd ? "flex-end" : "flex-start" }}
    >
      <a
        rel="noreferrer noopener"
        target="_blank"
        href="https://www.facebook.com/BluebirdResourceCentre/"
      >
        <FontAwesomeIcon icon={["fab", "facebook-f"]} />
      </a>
      <a
        rel="noreferrer noopener"
        href="mailto:EducareLifeskillsCentre@hotmail.com"
      >
        <FontAwesomeIcon icon={["fas", "envelope"]} />
      </a>
      <a rel="noreferrer noopener" href="tel:07983109161">
        <FontAwesomeIcon icon={["fas", "phone"]} />
      </a>
    </div>
  );
};

export default SocialContactIcons;
