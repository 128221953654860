import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import SEO from "@components/SEO";

import Layout from "@components/Layout";
import Hero from "@views/home/Hero";
import AboutSection from "@views/home/AboutSection";
import ResourceCenter from "@views/home/ResourceCenter";

export const IndexPageTemplate = ({ hero, about, resources, admin }) => (
  <div>
    {!admin && (
      <SEO description="Providing work and life skills training for people of all ages with physical and learning disabilities" />
    )}
    <Hero
      title={hero?.hero_title}
      text={hero?.hero_text}
      heroImage={hero?.hero_image}
    />
    <AboutSection
      headingImage={about?.about_heading_image}
      headingTitle={about?.about_heading_title}
      headingText={about?.about_heading_text}
      shapeTitle1={about?.about_shape1_title}
      shapeText1={about?.about_shape1_text}
      shapeTitle2={about?.about_shape2_title}
      shapeText2={about?.about_shape2_text}
      shapeTitle3={about?.about_shape3_title}
      shapeText3={about?.about_shape3_text}
      shapeTitle4={about?.about_shape4_title}
      shapeText4={about?.about_shape4_text}
    />
    <ResourceCenter
      heading={resources?.resources_heading}
      cards={resources?.resources_cards}
      donationTitle={resources?.donation_title}
      donationText={resources?.donation_text}
      donationBtnText={resources?.donation_button_text}
      reviews={resources?.review_cards}
    />
  </div>
);

IndexPageTemplate.propTypes = {
  hero: PropTypes.object,
  about: PropTypes.object,
  resources: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        hero={frontmatter?.hero}
        about={frontmatter?.about}
        resources={frontmatter?.resources}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        hero {
          hero_title
          hero_text
          hero_image {
            publicURL
          }
        }
        about {
          about_heading_image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          about_heading_title
          about_heading_text
          about_shape1_title
          about_shape1_text
          about_shape2_title
          about_shape2_text
          about_shape3_title
          about_shape3_text
          about_shape4_title
          about_shape4_text
        }
        resources {
          resources_heading
          resources_cards {
            image {
              publicURL
            }
            title
            link
          }
          donation_title
          donation_text
          donation_button_text
          review_cards {
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            name
            stars
            text
          }
        }
      }
    }
  }
`;
